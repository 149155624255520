import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

const BackgroundImg = (props) => (
  <StaticQuery query={graphql`
    query {
      preschoolImage: file(relativePath: { eq: "classes/preschool.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      recreationalImage: file(relativePath: { eq: "classes/recreational.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      teamImage: file(relativePath: { eq: "classes/team.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
    }
  `}
    render={ data => {
      // Set ImageData.
      const imageData = data[props.src].childImageSharp.fluid
      return (
        <BackgroundImage Tag="header"
                          classId={props.id || ''}
                          className={props.className}
                          fluid={imageData}
                          backgroundColor={`rgba(39, 121, 189, 0)`}
        >
          {props.children}
        </BackgroundImage>
      )
    } }
  />
)

export default BackgroundImg
