import React from 'react'

import '../css/loading-spinner.css'

// Credit to https://loading.io/css/

const LoadingSpinner = () => (
  <div className="lds-ellipsis">
    <div></div><div></div><div></div><div></div>
  </div>
)

export default LoadingSpinner
