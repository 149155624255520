import React from 'react'
import moment from 'moment'
import { extractAge, analyticsEvent } from '../lib/utils'

const ClassCard = ({ classObj }) => {
  const {
    end_date,
    end_time,
    meeting_days,
    max_age,
    min_age,
    name,
    online_reg_link,
    openings,
    reg_start_date,
    start_date,
    start_time,
    tuition,
    location_name: location,
  } = classObj
  const availableSeats =
    openings.calculated_openings > 0 ? openings.calculated_openings : 0

  return (
    <div className="border border-dashed border-grey rounded p-4 mx-3 md:mx-0 mb-4 bg-white">
      <div className="flex pb-4">
        <div className="w-1/2">
          <span className="font-bold">{name}</span>
        </div>
        <div className="w-1/2 text-right text-grey-darkest">
          Openings:
          <span className="ml-4 font-bold">{availableSeats}</span>
        </div>
      </div>
      <div className="lg:flex leading-loose text-grey-darkest">
        <div className="w-full lg:w-1/3">
          <div className="flex flex-wrap">
            <div className="w-1/3 md:w-1/5">Days:</div>
            <div className="w-2/3 md:w-4/5 font-bold">{days(meeting_days)}</div>
            <div className="w-1/3 md:w-1/5">Times:</div>
            <div className="w-2/3 md:w-4/5 font-bold">
              {times(start_time, end_time)}
            </div>
            <div className="w-1/3 md:w-1/5">Dates:</div>
            <div className="w-2/3 md:w-4/5 font-bold">
              {dates(start_date, end_date)}
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/3">
          <div className="flex flex-wrap">
            <div className="w-1/3 md:w-1/5">Ages:</div>
            <div className="w-2/3 md:w-4/5 font-bold">
              {ages(min_age, max_age)}
            </div>
            <div className="w-1/3 md:w-1/5">Tuition:</div>
            <div className="w-2/3 md:w-4/5 font-bold">
              ${tuition.fee.toFixed(2)}
            </div>
            <div className="w-1/3 md:w-1/5">Location:</div>
            <div className="w-2/3 md:w-4/5 font-bold">{location}</div>
          </div>
        </div>
        <div className="w-full lg:w-1/3 mt-4 text-center text-base self-end">
          {registerButton(
            online_reg_link,
            availableSeats,
            reg_start_date,
            location
          )}
        </div>
      </div>
    </div>
  )
}

function registerLink(uri) {
  // Example class reg link: https://app.jackrabbitclass.com/reg.asp?id=513632&preLoadClassID=9421711

  const LINK_TEMPLATE = 'https://app.jackrabbitclass.com/reg.asp?id=513632&'
  const SLUG_REGEX = /preLoadClassID=[0-9]*/
  const slug = uri.match(SLUG_REGEX)

  return `${LINK_TEMPLATE}${slug}`
}

function handleClick(e, online_reg_link, location) {
  e.preventDefault()

  const confirmationText = `You are about to register for a class at the **${location.toUpperCase()}** location. Please select OK to continue or CANCEL to select a different class.`

  // eslint-disable-next-line
  if (confirm(confirmationText) === true) {
    analyticsEvent('select_content', {
      content_id: online_reg_link,
    })
    window.location.href = registerLink(online_reg_link)
  }
}

function registerButton(
  online_reg_link,
  availableSeats,
  reg_start_date,
  location
) {
  if (online_reg_link) {
    return (
      <button
        className={`block w-full lg:inline-block lg:w-32 lg:float-right font-bold py-2 text-white rounded no-underline ${
          availableSeats > 0
            ? 'bg-purple-dark hover:bg-purple'
            : 'bg-orange-dark hover:bg-orange'
        }`}
        onClick={e => {
          handleClick(e, online_reg_link, location)
        }}
      >
        {availableSeats > 0 ? 'Register' : 'Wait List'}
      </button>
    )
  } else {
    return (
      <button
        className={`block w-full lg:inline-block lg:w-48 lg:float-right font-bold py-2 text-white rounded no-underline bg-grey`}
        onClick={e => {
          e.preventDefault()
        }}
      >
        Registration Opens {formatDate(reg_start_date)}
      </button>
    )
  }
}

function days(meeting_days) {
  const days = Object.keys(meeting_days).filter(day => meeting_days[day] === true)
  return (
    <span className="capitalize">
      { days.join(', ') }
    </span>
  )
}

function formatDate(date) {
  return moment(date).format('MM/DD/YYYY')
}

function dates(start, end) {
  return (`${formatDate(start)} - ${formatDate(end)}`)
}

function times(start, end) {
  const template = time => `2019-01-01 ${time}`
  const format = timestamp => moment(template(timestamp)).format('hh:mma')
  return `${format(start)} - ${format(end)}`
}

function ages(min, max) {
  const minAge = extractAge(min)
  const maxAge = extractAge(max)

  return `${minAge} - ${maxAge}`
}

export default ClassCard
