import React from 'react'
import InfoIcon from './info-icon'
import ErrorIcon from './error-icon'

const Alert = ({ type, header, body }) => {
  const colorTypes = {
    error: 'red',
    warn: 'orange',
    info: 'blue',
  }
  const alertColor = colorTypes[type]

  const iconTypes = {
    error: <ErrorIcon className={`mr-4 text-${alertColor}`}></ErrorIcon>,
    warn: <ErrorIcon className={`mr-4 text-${alertColor}`}></ErrorIcon>,
    info: <InfoIcon className={`mr-4 text-${alertColor}`}></InfoIcon>,
  }
  const icon = iconTypes[type]

  return (
    // purgecss whitelist: bg-red-lightest, bg-blue-lightest, border-red, border-blue, text-red, text-blue, bg-orange-lightest, border-orange, text=orange-darkest
    <div
      className={`mx-3 md:mx-0 my-8 bg-${alertColor}-lightest border-t-4 border-${alertColor} rounded-b text-${alertColor}-darkest px-4 py-3 shadow-md`}
      role="alert"
    >
      <div className="flex">
        <div className="py-1">{icon}</div>
        <div>
          <p className="font-bold">{header}</p>
          <p>{body}</p>
        </div>
      </div>
    </div>
  )
}

export default Alert
